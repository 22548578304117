// react
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// store
import { addBasketConfiguration, removeProductFromBasketConfiguration, removeFromBasket } from "../../store/actions";

import { LOADING_TIME } from "../../store/config";
import { searchService } from "../../store/services/ServiceSearch";
// components
import EmptyBasket from "./EmptyBasket";
import BasketList from "./BasketList";
import Footer from "../../components/general/Footer";
import Navbar from "../../components/general/Navbar";
import { Container, Header, Segment } from "semantic-ui-react";
// others
import ReactGa from "react-ga";

// component
function Basket() {
    // state
    const [isLoading, setIsLoading] = useState(true);
    const [basketItems, setBasketItems] = useState([]);
    const [items, setItems] = useState([])

    let dispatch = useDispatch();
    const basket = useSelector((state) => state.basket);
    const basketConfig = useSelector((state) => state.basket_config);

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const result = await searchService.getBasketItems(basket);

            if (result.status === 200) {
                setBasketItems(result.response);
                let itemList = []
                for (let i = 0; i < result.response.length; i++) {
                    let itemPrice = 0
                    if (result.response[i].is_discounted) {
                        itemPrice += parseFloat(result.response[i].discountPrice);
                    } else {
                        itemPrice += parseFloat(result.response[i].price);
                    }

                    itemList.push({ id: result.response[i].id, amount: 1, product: result.response[i].event_type, price: itemPrice })
                }

                let adjustedList = itemList.map(listItem => {
                    const storedItem = basketConfig.find(basketItem => basketItem.id === listItem.id)
                    if (storedItem !== undefined) {
                        listItem.amount = storedItem.amount
                    }

                    return listItem
                })

                setItems(adjustedList)
                dispatch(addBasketConfiguration(adjustedList))
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };

        fetchData();
        // eslint-disable-next-line
    }, []);

    // Google Analytics
    useEffect(() => {
        ReactGa.set({ page: window.location.pathname });
        ReactGa.pageview(window.location.pathname);
    }, []);


    useEffect(() => {
        dispatch(addBasketConfiguration(items))
        // eslint-disable-next-line
    }, [items]);

    const removeProductFromBasket = (item) => {
        dispatch(removeFromBasket(item));
        setBasketItems((prevState) => prevState.filter((event) => event.id !== item.id));

        dispatch(removeProductFromBasketConfiguration(item.id))
        setItems((prevState) => prevState.filter((basketItem) => basketItem.id !== item.id));
    };

    const countTotal = () => {
        let total = 0
        for (let i = 0; i < items.length; i++) {
            let totalPrice = items[i].price * items[i].amount
            total += totalPrice
        }

        return parseFloat(total).toFixed(2)
    }

    // template
    return (
        <>
            <Navbar linkBack="/" />
            <Container
                style={{
                    width: '75%',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                }}
            >
                <Header
                    as="h2"
                    content="Nákupný košík - zhrnutie"
                    textAlign="center"
                    style={{ padding: "1rem", marginTop: "2rem" }}
                />
                <Segment loading={isLoading}>
                    {isLoading &&
                        <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem' }}>
                            načítavám obsah košíka...
                        </p>
                    }
                    {!isLoading &&
                        <>
                            {basketItems.length ? (
                                <BasketList
                                    basket={basket}
                                    basketItems={basketItems}
                                    items={items}
                                    setItems={setItems}
                                    countTotal={countTotal}
                                    removeProductFromBasket={removeProductFromBasket}
                                    setBasketEvents={setBasketItems}
                                />
                            ) : (
                                <EmptyBasket />
                            )}
                        </>
                    }

                </Segment>
            </Container>

            <div className="website" style={{ marginTop: "10%" }}>
                <Footer />
            </div>
        </>
    );
}

export default Basket;
