// react
import React /*, { useState }*/ from 'react'
import { Link } from 'react-router-dom'
//import { useSelector } from 'react-redux'
// store
import { truncateString } from '../../store/services/helpers/functions'
// components
// import { DistanceMatrixService } from "@react-google-maps/api";
import { Card, /*Icon,*/ Label, Grid } from 'semantic-ui-react'
// others
import moment from 'moment'

// component
function InfoCard(props) {
    // eslint-disable-next-line
    //const basket = useSelector((state) => state.basket)

    // states related to positions
    // const [usersPosition] = useState(props.usersPosition);
    // const [eventPosition] = useState({
    //     lat: parseFloat(props.event.address.latitude),
    //     lng: parseFloat(props.event.address.longitude),
    // });

    // states related to calculations
    // const [travelDistance, setTravelDistance] = useState("");
    // const [walkDuration, setWalkDuration] = useState("");
    // const [carDuration, setCarDuration] = useState("");

    // const distanceCalculationCar = (response) => {
    //     if (response !== null) {
    //         if (response.rows[0].elements[0].distance.text !== travelDistance)
    //             setTravelDistance(response.rows[0].elements[0].distance.text);

    //         if (response.rows[0].elements[0].duration.text !== carDuration)
    //             setCarDuration(response.rows[0].elements[0].duration.text);
    //     }
    // };

    // const distanceCalculationWalk = (response) => {
    //     if (response !== null)
    //         if (response.rows[0].elements[0].duration.text !== walkDuration)
    //             setWalkDuration(response.rows[0].elements[0].duration.text);
    // };

    // template
    return (
        <>
            <Card
                as={Link}
                style={{
                    /*boxShadow: "none",*/ marginTop: '0.1rem',
                    maxWidth: '200px',
                }}
                centered
                className={`cardhover cardOverlaying ${props.selectedMarker.id === props.event.id && 'highlight-card'}`}
                to={`/activity/${props.event.id}/detail`}
            >
                <div
                    style={{
                        width: '100%',
                        height: '85px',
                        backgroundImage:
                            props.event.activity.mainImage > 0
                                ? 'url(' +
                                props.event.activity.gallery.find(
                                    (image) => image.id === props.event.activity.mainImage
                                ).source +
                                ')'
                                : 'url(/images/general/image_placeholder.jpg)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />

                <Card.Content>
                    <Card.Header>
                        <Grid columns={2}>
                            <Grid.Row style={{ paddingTop: '0rem' }}>
                                <Grid.Column style={{ paddingLeft: 0 }} width="16">
                                    {truncateString(props.event.activity.name, 20)}
                                </Grid.Column>
                                <Grid.Column style={{ fontSize: '1rem', paddingLeft: 0 }}>
                                    <small>
                                        {props.event?.event_type === 'future' &&
                                            moment(props.event.expiration).format('DD.MM.YYYY HH:mm')}
                                        {(props.event?.event_type !== 'future' && props.event.start && props.event.ends) &&
                                            moment(props.event.starts).format('DD.MM.YYYY HH:mm') +
                                            ' - ' +
                                            moment(props.event.ends).format('HH:mm')}
                                    </small>
                                </Grid.Column>
                                <Grid.Column
                                    style={{
                                        paddingLeft: 0,
                                        display: props.event.is_paid ? 'block' : 'none',
                                    }}
                                    width="16"
                                >
                                    <div style={{ color: '#d60c8c' }}>
                                        <b>
                                            {props.event.is_discounted ? (
                                                <>
                                                    {props.event.activity.discountPrice + ' € '}
                                                    <sup style={{ opacity: '0.8' }}>
                                                        <small style={{ textDecoration: 'line-through' }}>
                                                            <b>{props.event.activity.price} €</b>
                                                        </small>
                                                    </sup>
                                                </>
                                            ) : (
                                                props.event.activity.price + ' €'
                                            )}{' '}
                                        </b>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Header>
                    <Grid columns={1}>
                        <Grid.Row style={{ padding: 0 }}>
                            {/* <Grid.Column>
                                    {props.rating}
                                    <Rating
                                        icon="star"
                                        className="icnPink"
                                        maxRating={5}
                                        disabled
                                        defaultRating={3}
                                    />
                                </Grid.Column> */}
                        </Grid.Row>
                        <Grid.Row centered style={{ padding: 0 }}>
                            {props.event.is_highlighted && props.event.event_type === 'solidarity' && (
                                <Label color="green" style={{ width: '100%' }} className="special-event">
                                    #APevent
                                </Label>
                            )}

                            {!props.event.is_highlighted && props.event.event_type === 'solidarity' && (
                                <Label color="blue" style={{ width: '100%' }}>
                                    #APsolidarity
                                </Label>
                            )}

                            {props.event.event_type !== 'solidarity' && props.event.is_paid && (
                                <Label color="red" style={{ width: '100%' }}>
                                    #APwalkIn
                                </Label>
                            )}

                            {props.event.event_type !== 'solidarity' && !props.event.is_paid && (
                                <Label color="yellow" style={{ width: '100%' }}>
                                    #APimage
                                </Label>
                            )}
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>
        </>
    )
}

export default InfoCard
