// react
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
// store
import { planningService } from "../../../../store/services/ServicePlanning";
// components
import { Icon, Table, Segment } from "semantic-ui-react";
import ModalConfirm from "../../../../components/modals/ModalConfirm";
import moment from "moment";

const PermanentPointsList = () => {
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(true)
    const [toDelete, setToDelete] = useState({
        open: false,
        id: 0
    })

    useEffect(() => {
        async function getEvents() {
            setLoading(true)

            const result = await planningService.getPermanentEvents()
            if (result.length > 0) {
                setEvents(result)
            }

            setLoading(false)
        }

        getEvents()
    }, [])

    const handlePermaRemove = async () => {
        const result = await planningService.cancelPermaEvent({}, toDelete.id)
        if (result) {
            setEvents(prev => prev.filter(item => item.id !== toDelete.id))
            setToDelete({ open: false, id: 0 })
        }
    }

    return (
        <Segment loading={loading} style={{ background: "transparent", border: "none", shadow: "none", padding: 0, margin: 0 }}>
            {loading &&
                <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem', paddingBottom: '4rem' }}>
                    načítavám zoznam...
                </p>
            }
            {!loading &&
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Aktivita</Table.HeaderCell>
                            <Table.HeaderCell>Platné do</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" style={{ width: "70px" }}>Akcia</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {events.length === 0 &&
                            <Table.Row>
                                <Table.Cell colSpan="3" style={{ fontWeight: "bold", textAlign: "center" }}>
                                    Neexistuje záznam pre zobrazenie.
                                </Table.Cell>
                            </Table.Row>
                        }
                        {events.map((event, index) => (
                            <Table.Row key={index}>
                                <Table.Cell>
                                    <strong><Link style={{ color: "#d60d8c" }} to={`/activity/${event.id}/detail`} target="_blank">{event.activity.name}</Link></strong> <br />
                                    <small>{event.address.name}</small>
                                </Table.Cell>
                                <Table.Cell>{event.valid_until ? moment(event.valid_until).format("DD.MM.YYYY") : <span style={{ opacity: 0.7 }}> neobmedzené </span>}</Table.Cell>
                                <Table.Cell textAlign="center" style={{ width: "70px" }}>
                                    <Icon onClick={() => setToDelete({ open: true, id: event.id })} name="trash" style={{ cursor: "pointer", color: "red" }} />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            }

            <ModalConfirm
                open={toDelete.open}
                header="Deaktivácia pevného bodu"
                content="Si si istý(á), že chceš deaktivovať tento pevný bod?"
                confirmButton="Áno"
                cancelButton="Nie"
                onCancel={() => setToDelete({ open: false, id: 0 })}
                onConfirm={() => handlePermaRemove()}
            />
        </Segment>
    )
}

export default PermanentPointsList