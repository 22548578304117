// react
import React, { Fragment, useState } from "react";

// components
import PaymentModal from "./PaymentModal";
import BasketItem from "./BasketItem";
import { Table, Grid, Button, Form, Divider, Popup, Icon } from "semantic-ui-react";

const summarizeTaxes = (items, configItems) => {
    const taxSummary = {};
    let grandTotalBase = 0;
    let grandTotalTax = 0;
    let grandTotalPrice = 0;

    items.forEach((item) => {
        const amount = configItems.find(config => config.id === item.id)?.amount || 1
        const prices = item.prices;
        const taxRate = prices.tax_rate;
        const isDiscounted = item.is_discounted;

        // Select the correct base and tax amount based on discount status
        const baseAmount = (isDiscounted ? prices.discount_price_base_amount : prices.price_base_amount) * amount;
        const taxAmount = (isDiscounted ? prices.discount_price_tax_amount : prices.price_tax_amount) * amount;
        const totalPrice = baseAmount + taxAmount;

        // Track grand totals
        grandTotalBase += baseAmount;
        grandTotalTax += taxAmount;
        grandTotalPrice += totalPrice;

        // If tax rate doesn't exist in summary, initialize it
        if (!taxSummary[taxRate]) {
            taxSummary[taxRate] = {
                tax_rate: `${taxRate} %`,  // Format as percentage
                total_base_amount: 0,
                total_tax_amount: 0,
                total_price: 0
            };
        }

        // Accumulate values per tax rate
        taxSummary[taxRate].total_base_amount += baseAmount;
        taxSummary[taxRate].total_tax_amount += taxAmount;
        taxSummary[taxRate].total_price += totalPrice;
    });

    // Convert object to sorted array and ensure 2 decimal places as STRING
    const taxList = Object.values(taxSummary).sort((a, b) => parseFloat(a.tax_rate) - parseFloat(b.tax_rate))
        .map(entry => ({
            tax_rate: entry.tax_rate,
            total_base_amount: entry.total_base_amount.toFixed(2),
            total_tax_amount: entry.total_tax_amount.toFixed(2),
            total_price: entry.total_price.toFixed(2) // Ensure EUR formatting
        }));

    // Add final row with grand totals
    taxList.push({
        tax_rate: "Total",
        total_base_amount: grandTotalBase.toFixed(2),
        total_tax_amount: grandTotalTax.toFixed(2),
        total_price: grandTotalPrice.toFixed(2)
    });

    return taxList;
};


// component
const BasketList = ({
    basket,
    basketItems,
    items,
    setItems,
    countTotal,
    removeProductFromBasket,
    setBasketEvents,
}) => {
    // state
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)

    const sumarizedTaxes = summarizeTaxes(basketItems, items)

    const uniqueProviders = [
        ...new Map(basketItems.map(item => [item.provider.id, item.provider])).values()
    ]

    const providerNames = uniqueProviders.map(provider => provider.name);


    // template
    return (
        <Fragment>
            <Grid stackable>
                <Grid.Row style={{ paddingBottom: 0 }}>
                    <Grid.Column width={16} only="computer">
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell>
                                    #
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Názov
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Množstvo
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Cena
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ width: "50px" }}>

                                </Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                                {basketItems.map((item, index) => (
                                    <BasketItem
                                        as="tableCell"
                                        index={index}
                                        key={item.id}
                                        item={item}
                                        items={items}
                                        setItems={setItems}
                                        removeProductFromBasket={removeProductFromBasket}
                                    />
                                ))}
                            </Table.Body>
                        </Table>


                    </Grid.Column>
                    <Grid.Column width={16} only="tablet mobile">
                        {basketItems.map((item, index) => (
                            <BasketItem
                                as="card"
                                index={index}
                                key={item.id}
                                item={item}
                                items={items}
                                setItems={setItems}
                                removeProductFromBasket={removeProductFromBasket}
                            />
                        ))}
                    </Grid.Column>
                </Grid.Row>

            </Grid>

            <Grid stackable>
                <Grid.Row columns="2" only="computer">
                    <Grid.Column>
                        <p>
                            {providerNames.length > 1 && (
                                <>
                                    <strong>V košíku sa nachádzajú aktivity od viacerích zadávateľov:</strong>
                                    <br />
                                    {providerNames.join(', ')}
                                    <br />
                                    <br />
                                </>
                            )}
                            <strong>Spôsob úhrady:</strong> Platba kartou (CardPay TB)
                            <br />
                            <strong>Spôsob dodania:</strong>{' '}
                            <Popup
                                trigger={<span>Online <Icon name="info circle" /></span>}
                                content={<p>Dostupné v klientskom prostredí <strong>"Moje termíny"</strong> po prihlásení</p>}
                            />
                            <br />
                            <strong>Počet položiek:</strong> {basketItems.length}
                        </p>
                    </Grid.Column>
                    <Grid.Column>
                        <Table unstackable basic="very" className="no-table-border">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Sadzba - %</Table.HeaderCell>
                                    <Table.HeaderCell>Základ</Table.HeaderCell>
                                    <Table.HeaderCell>DPH</Table.HeaderCell>
                                    <Table.HeaderCell>Suma</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {sumarizedTaxes.map((tax, idx) => {
                                    const isLastRow = idx === sumarizedTaxes.length - 1;
                                    return (
                                        <Table.Row key={idx} style={isLastRow ? { fontWeight: 'bold' } : null}>
                                            <Table.Cell>{tax.tax_rate}</Table.Cell>
                                            <Table.Cell>{tax.total_base_amount}</Table.Cell>
                                            <Table.Cell>{tax.total_tax_amount}</Table.Cell>
                                            <Table.Cell>{tax.total_price} EUR</Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns="2" only="tablet mobile">
                    <Grid.Column width={16}>
                        <Divider style={{ marginTop: 0 }} />
                        <p>
                            {providerNames.length > 1 && (
                                <>
                                    <strong>V košíku sa nachádzajú aktivity od viacerích zadávateľov:</strong>
                                    <br />
                                    {providerNames.join(', ')}
                                    <br />
                                    <br />
                                </>
                            )}
                            <strong>Spôsob úhrady:</strong> Platba kartou (CardPay TB)
                            <br />
                            <strong>Spôsob dodania:</strong>{' '}
                            <Popup
                                trigger={<span>Online <Icon name="info circle" /></span>}
                                content={<p>Dostupné v klientskom prostredí <strong>"Moje termíny"</strong> po prihlásení</p>}
                            />
                            <br />
                            <strong>Počet položiek:</strong> {basketItems.length}
                        </p>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Table unstackable className="no-table-border" style={{ marginTop: "1rem" }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Sadzba - %</Table.HeaderCell>
                                    <Table.HeaderCell>Základ</Table.HeaderCell>
                                    <Table.HeaderCell>DPH</Table.HeaderCell>
                                    <Table.HeaderCell>Suma</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {sumarizedTaxes.map((tax, idx) => {
                                    const isLastRow = idx === sumarizedTaxes.length - 1;
                                    return (
                                        <Table.Row key={idx} style={isLastRow ? { fontWeight: 'bold' } : null}>
                                            <Table.Cell>{tax.tax_rate}</Table.Cell>
                                            <Table.Cell>{tax.total_base_amount}</Table.Cell>
                                            <Table.Cell>{tax.total_tax_amount}</Table.Cell>
                                            <Table.Cell>{tax.total_price} EUR</Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider />

            <Grid stackable>
                <Grid.Row columns={1}>
                    <Grid.Column width={16} only="computer" textAlign="right">
                        <PaymentModal
                            isOpen={isPaymentModalOpen}
                            onClose={() => setIsPaymentModalOpen(false)}
                            onOpen={() => setIsPaymentModalOpen(true)}
                            items={items}
                            setItems={setItems}
                            trigger={
                                <Button
                                    //onClick={() => setIsPaymentModalOpen(true)}
                                    type="button"
                                    className="button-pay-order button-pay-fluid"
                                    color="pink"
                                    size="huge"

                                >
                                    Zaplatiť objednávku ({countTotal()} EUR)

                                </Button>
                            }
                            basket_items={basket}
                            removeItem={removeProductFromBasket}
                            setBasketEvents={setBasketEvents}
                        />

                    </Grid.Column>
                    <Grid.Column width={16} only="tablet mobile">
                        <Form onSubmit={(e) => e.preventDefault()}>
                            <Form.Field style={{ textAlign: "right" }}>
                                <PaymentModal
                                    open={isPaymentModalOpen}
                                    onClose={() => setIsPaymentModalOpen(false)}
                                    //onOpen={() => setIsPaymentModalOpen(true)}
                                    items={items}
                                    setItems={setItems}
                                    trigger={
                                        <Button
                                            onClick={() => setIsPaymentModalOpen(true)}
                                            type="button"
                                            className="button-pay-order button-pay-fluid"
                                            color="pink"
                                            size="huge"

                                        >
                                            Zaplatiť objednávku ({countTotal()} EUR)

                                        </Button>
                                    }
                                    basket_items={basket}
                                    removeItem={removeProductFromBasket}
                                    setBasketEvents={setBasketEvents}
                                />
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>


        </Fragment>
    );
};

export default BasketList;
