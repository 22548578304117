// react
import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
// store
import { planningService } from "../../../../store/services/ServicePlanning";
// components
import SuperModal from '../../../../components/modals/SuperModal'
import Loading from "../../../../components/others/Loading";
import PlanningEvent from "./PlanningEvent";
import PlanningAddEvent from "./PlanningAddEvent";
import PlanningEditEvent from "./PlanningEditEvent";
import PlanningDeleteEvent from "./PlanningDeleteEvent";
import PlanningDeleteDay from "./PlanningDeleteDay";
import PlanningModalMessage from "./PlanningModalMessage";
import ButtonAddClick from "../../../../components/buttons/ButtonAddClick";
import PlanningCalendarHeader from "./PlanningCalendarHeader";
import PlanningCalendarDay from "./PlanningCalendarDay";
import PlanningCalendarDayMobile from "./PlanningCalendarDayMobile";
import { Header, Grid, Table, Button, Icon } from "semantic-ui-react";
import PermanentPointsList from "./PermanentPointsList";
// others
import moment from "moment";

moment.locale("sk");

// component
function PlanningCalendar() {
    const user = useSelector(state => state.user)
    // state
    const [isLoading, setIsLoading] = useState(true);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [modalAddEventOpen, setModalAddEventOpen] = useState(false);
    const [modalEditEventOpen, setModalEditEventOpen] = useState(false);
    const [modalDeleteEventOpen, setModalDeleteEventOpen] = useState(false);
    const [modalDeleteDayOpen, setModalDeleteDayOpen] = useState(false);
    const [editEventInfo, setEditEventInfo] = useState(0);
    const [deleteEventInfo, setDeleteEventInfo] = useState(0);
    const [modalDeleteDayDate, setModalDeleteDayDate] = useState("");
    const [events, setEvents] = useState([]);
    const [modalMessageOpen, setModalMessageOpen] = useState(false);
    const [modalMessageText, setModalMessageText] = useState("");
    const [modalMessageUpdate, setModalMessageUpdate] = useState(false);

    // set actual dates for days
    const [dateMon, setDateMon] = useState(
        moment(currentDate).subtract(currentDate.getDay() - 1, "days")
    );
    const [dateTue, setDateTue] = useState(
        moment(currentDate).add(2 - currentDate.getDay(), "days")
    );
    const [dateWed, setDateWed] = useState(
        moment(currentDate).add(3 - currentDate.getDay(), "days")
    );
    const [dateThu, setDateThu] = useState(
        moment(currentDate).add(4 - currentDate.getDay(), "days")
    );
    const [dateFri, setDateFri] = useState(
        moment(currentDate).add(5 - currentDate.getDay(), "days")
    );
    const [dateSat, setDateSat] = useState(
        moment(currentDate).add(6 - currentDate.getDay(), "days")
    );
    const [dateSun, setDateSun] = useState(
        moment(currentDate).add(7 - currentDate.getDay(), "days")
    );

    // fetch data - events
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const resultData = await planningService.getEvents(
                "?date_from=" +
                moment(dateMon).format("YYYY-MM-DD") +
                "&date_to=" +
                moment(dateSun).format("YYYY-MM-DD")
            );
            setEvents(resultData);
            setIsLoading(false);
        };

        fetchData();
    }, [dateMon, dateSun]);

    /** controls for modals Add event, Edit event, Delete event, Modal Message **/
    // add event
    const handleModalAddEventOpen = () => {
        setModalAddEventOpen(true);
    };

    const handleModalAddEventClose = () => {
        setModalAddEventOpen(false);
        // open modal with message
        if (modalMessageUpdate) {
            handleModalMessageOpen();
            setModalMessageUpdate(false);
        }
    };

    // edit event
    const handleModalEditEventOpen = () => {
        setModalEditEventOpen(true);
    };

    const handleModalEditEventClose = () => {
        setModalEditEventOpen(false);
    };

    const changeModalEditEventInfo = (eventInfo) => {
        setEditEventInfo(eventInfo);
    };

    // delete event
    const handleModalDeleteEventOpen = () => {
        setModalDeleteEventOpen(true);
    };

    const handleModalDeleteEventClose = () => {
        setModalDeleteEventOpen(false);
    };

    const changeModalDeleteEventInfo = (eventInfo) => {
        setDeleteEventInfo(eventInfo);
    };

    // delete day events
    const handleModalDeleteDayOpen = () => {
        setModalDeleteDayOpen(true);
    };

    const handleModalDeleteDayClose = () => {
        setModalDeleteDayOpen(false);
    };

    const startDeleteDay = (date) => {
        setModalDeleteDayDate(date);
        handleModalDeleteDayOpen();
    };

    // modal message
    const handleModalMessageOpen = () => {
        setModalMessageOpen(true);
    };

    const handleModalMessageClose = () => {
        setModalMessageOpen(false);
    };

    const changeModalMessageText = (text) => {
        setModalMessageText(text);
    };

    const changeModalMessageUpdate = (update) => {
        setModalMessageUpdate(update);
        // open modal with message
        if (update) {
            setModalMessageOpen(true);
            setModalMessageUpdate(false);
            setWeekDates(currentDate);
        }
    };

    /** component functions **/

    const previousWeek = () => {
        const newCurrentDate = moment(currentDate).subtract(7, "days").toDate();
        setCurrentDate(newCurrentDate);
        setWeekDates(newCurrentDate);
    };

    const nextWeek = () => {
        const newCurrentDate = moment(currentDate).add(7, "days").toDate();
        setCurrentDate(newCurrentDate);
        setWeekDates(newCurrentDate);
    };

    // set date for every day in a week according to a current day
    const setWeekDates = (date) => {
        const monday = moment(date).subtract(currentDate.getDay() - 1, "days");
        if (moment(monday).format("YYYY-MM-DD") !== moment(dateMon).format("YYYY-MM-DD")) {
            setDateMon(monday);
        }
        setDateTue(moment(monday).add(1, "days"));
        setDateWed(moment(monday).add(2, "days"));
        setDateThu(moment(monday).add(3, "days"));
        setDateFri(moment(monday).add(4, "days"));
        setDateSat(moment(monday).add(5, "days"));
        setDateSun(moment(monday).add(6, "days"));
    };

    // add events to calendar for a day
    const setDayPlan = (data, day) => {
        const dayDate = moment(day).format("YYYY-MM-DD");
        let dayEvents = data.filter(
            (activity) =>
                dayDate === moment(activity.starts).format("YYYY-MM-DD")
        );

        // text when no events are planned on this day
        if (dayEvents.length < 1) {
            return <Header as="h4">Žiadne udalosti</Header>;
        }
        // else
        return dayEvents.map((event, eventId) => (
            <PlanningEvent
                key={eventId}
                handleModalEditEventOpen={handleModalEditEventOpen}
                changeModalEditEventInfo={changeModalEditEventInfo}
                handleModalDeleteEventOpen={handleModalDeleteEventOpen}
                changeModalDeleteEventInfo={changeModalDeleteEventInfo}
                eventInfo={event}
            />
        ));
    };

    // check if there is some event on the day
    const hasDayEvents = (data, day) => {
        const dayDate = moment(day).format("YYYY-MM-DD");
        let dayEvents = data.filter(
            (activity) =>
                dayDate === moment(activity.starts).format("YYYY-MM-DD")
        );

        // return false if there are no events
        return dayEvents.length < 1;
    };

    // render
    const currentWeekDate = `${dateMon.format("D.M.Y")} -
        ${dateSun.format("D.M.Y")}`;

    // template
    return (
        <Fragment>
            <Fragment>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column width="8">
                            <ButtonAddClick
                                text="Pridať udalosť"
                                onClick={handleModalAddEventOpen}
                            />
                            {user.is_town &&
                                <SuperModal
                                    size="small"
                                    centered={false}
                                    closeIcon
                                    header="Zoznam pevných bodov"
                                    content={<PermanentPointsList />}
                                    trigger={
                                        <Button size="large" color="black" type="button">
                                            <Icon name="list" />
                                            Zobraziť zoznam pevných bodov
                                        </Button>
                                    }
                                />
                            }
                        </Grid.Column>
                    </Grid.Row>

                    <PlanningCalendarHeader
                        currentWeekDate={currentWeekDate}
                        previousWeek={previousWeek}
                        nextWeek={nextWeek}
                    />

                    {isLoading ? (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Loading />
                        </div>
                    ) : (
                        <Grid.Row centered>
                            <Grid.Column width={16} only="tablet computer">
                                <Table structured celled textAlign="center">
                                    <Table.Header>
                                        <Table.Row>
                                            <PlanningCalendarDay
                                                dayShort="PON"
                                                dayDate={dateMon}
                                                hasDayEvents={hasDayEvents}
                                                events={events}
                                                startDeleteDay={startDeleteDay}
                                            />
                                            <PlanningCalendarDay
                                                dayShort="UTO"
                                                dayDate={dateTue}
                                                hasDayEvents={hasDayEvents}
                                                events={events}
                                                startDeleteDay={startDeleteDay}
                                            />
                                            <PlanningCalendarDay
                                                dayShort="STR"
                                                dayDate={dateWed}
                                                hasDayEvents={hasDayEvents}
                                                events={events}
                                                startDeleteDay={startDeleteDay}
                                            />
                                            <PlanningCalendarDay
                                                dayShort="ŠTV"
                                                dayDate={dateThu}
                                                hasDayEvents={hasDayEvents}
                                                events={events}
                                                startDeleteDay={startDeleteDay}
                                            />
                                            <PlanningCalendarDay
                                                dayShort="PIA"
                                                dayDate={dateFri}
                                                hasDayEvents={hasDayEvents}
                                                events={events}
                                                startDeleteDay={startDeleteDay}
                                            />
                                            <PlanningCalendarDay
                                                dayShort="SOB"
                                                dayDate={dateSat}
                                                hasDayEvents={hasDayEvents}
                                                events={events}
                                                startDeleteDay={startDeleteDay}
                                            />
                                            <PlanningCalendarDay
                                                dayShort="NED"
                                                dayDate={dateSun}
                                                hasDayEvents={hasDayEvents}
                                                events={events}
                                                startDeleteDay={startDeleteDay}
                                            />
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row verticalAlign="top">
                                            <Table.Cell>
                                                {setDayPlan(events, dateMon)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {setDayPlan(events, dateTue)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {setDayPlan(events, dateWed)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {setDayPlan(events, dateThu)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {setDayPlan(events, dateFri)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {setDayPlan(events, dateSat)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {setDayPlan(events, dateSun)}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Grid.Column>

                            <Grid.Column width={16} only="mobile">
                                <Fragment>
                                    <PlanningCalendarDayMobile
                                        dayShort="PON"
                                        dayDate={dateMon}
                                        hasDayEvents={hasDayEvents}
                                        events={events}
                                        startDeleteDay={startDeleteDay}
                                    />
                                    {setDayPlan(events, dateMon)}
                                </Fragment>

                                <Fragment>
                                    <PlanningCalendarDayMobile
                                        dayShort="UTO"
                                        dayDate={dateTue}
                                        hasDayEvents={hasDayEvents}
                                        events={events}
                                        startDeleteDay={startDeleteDay}
                                    />
                                    {setDayPlan(events, dateTue)}
                                </Fragment>

                                <Fragment>
                                    <PlanningCalendarDayMobile
                                        dayShort="STR"
                                        dayDate={dateWed}
                                        hasDayEvents={hasDayEvents}
                                        events={events}
                                        startDeleteDay={startDeleteDay}
                                    />
                                    {setDayPlan(events, dateWed)}
                                </Fragment>

                                <Fragment>
                                    <PlanningCalendarDayMobile
                                        dayShort="ŠTV"
                                        dayDate={dateThu}
                                        hasDayEvents={hasDayEvents}
                                        events={events}
                                        startDeleteDay={startDeleteDay}
                                    />
                                    {setDayPlan(events, dateThu)}
                                </Fragment>

                                <Fragment>
                                    <PlanningCalendarDayMobile
                                        dayShort="PIA"
                                        dayDate={dateFri}
                                        hasDayEvents={hasDayEvents}
                                        events={events}
                                        startDeleteDay={startDeleteDay}
                                    />
                                    {setDayPlan(events, dateFri)}
                                </Fragment>

                                <Fragment>
                                    <PlanningCalendarDayMobile
                                        dayShort="SOB"
                                        dayDate={dateSat}
                                        hasDayEvents={hasDayEvents}
                                        events={events}
                                        startDeleteDay={startDeleteDay}
                                    />
                                    {setDayPlan(events, dateSat)}
                                </Fragment>

                                <Fragment>
                                    <PlanningCalendarDayMobile
                                        dayShort="NED"
                                        dayDate={dateSun}
                                        hasDayEvents={hasDayEvents}
                                        events={events}
                                        startDeleteDay={startDeleteDay}
                                    />
                                    {setDayPlan(events, dateSun)}
                                </Fragment>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>

                <PlanningAddEvent
                    modalAddEventOpen={modalAddEventOpen}
                    handleModalAddEventClose={handleModalAddEventClose}
                    changeModalMessageText={changeModalMessageText}
                    changeModalMessageUpdate={changeModalMessageUpdate}
                />

                <PlanningEditEvent
                    modalEditEventOpen={modalEditEventOpen}
                    handleModalEditEventClose={handleModalEditEventClose}
                    editEventInfo={editEventInfo}
                    changeModalMessageText={changeModalMessageText}
                    changeModalMessageUpdate={changeModalMessageUpdate}
                />

                <PlanningDeleteEvent
                    modalDeleteEventOpen={modalDeleteEventOpen}
                    handleModalDeleteEventClose={handleModalDeleteEventClose}
                    deleteEventInfo={deleteEventInfo}
                    changeModalMessageText={changeModalMessageText}
                    changeModalMessageUpdate={changeModalMessageUpdate}
                />

                <PlanningDeleteDay
                    modalDeleteDayOpen={modalDeleteDayOpen}
                    handleModalDeleteDayClose={handleModalDeleteDayClose}
                    deleteDate={modalDeleteDayDate}
                    changeModalMessageText={changeModalMessageText}
                    changeModalMessageUpdate={changeModalMessageUpdate}
                />

                <PlanningModalMessage
                    modalMessageOpen={modalMessageOpen}
                    handleModalMessageClose={handleModalMessageClose}
                    modalMessageText={modalMessageText}
                />
            </Fragment>
        </Fragment>
    );
}

export default PlanningCalendar;
