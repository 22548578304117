// react
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// store
import { activityService } from '../../../../store/services/ServiceActivity'
import { planningService } from '../../../../store/services/ServicePlanning'
import { eventRepeatOptions } from '../../../../store/services/helpers/settings'
import { isEmpty, isDateError, isTimeError } from '../../../../store/services/helpers/validation'
// components
import { Form, Grid, Header, Icon, Divider, Button, Modal, Dimmer, Loader, Message } from 'semantic-ui-react'
import { DateInput, TimeInput } from 'semantic-ui-calendar-react'
// others
import moment from 'moment'
import 'moment/min/locales.min'

moment.locale('sk')

// component
function PlanningAddEventForm({ eventType, backModalStep, newMessage }) {
    const user = useSelector((state) => state.user)
    const isPermanent = eventType === "permanent"
    // state
    const [isLoading, setIsLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [activityId, setActivityId] = useState('')
    const [date, setDate] = useState('')
    const [timeStart, setTimeStart] = useState('')
    const [timeEnd, setTimeEnd] = useState('')
    const [place, setPlace] = useState('')
    const [repeat, setRepeat] = useState('1')
    const [dateRepeat, setDateRepeat] = useState('')
    const [validUntil, setValidUntil] = useState('')
    const [note, setNote] = useState('')
    const [activity, setActivity] = useState({})
    const [activities, setActivities] = useState([])
    const activitiesOptions = []
    const [placesOptions, setPlacesOptions] = useState([])
    const [isDiscounted, setIsDiscounted] = useState(false)
    const [isPaid, setIsPaid] = useState(user.is_town ? true : false)

    /** fetch data for form select input **/

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            const allActivities = await activityService.getCompanyActivities('')
            setActivities(allActivities)
            setIsLoading(false)
        }
        fetchData()
    }, [])

    // create options for select input from fetched data
    const setSelectOptions = (fetchedData, options) => {
        for (let i = 0; i < fetchedData.length; i++) {
            let option = {
                key: fetchedData[i].id,
                text: fetchedData[i].name,
                value: fetchedData[i].id,
            }
            options.push(option)
        }
    }

    // select input options - activities
    setSelectOptions(activities, activitiesOptions)

    /** component handlers **/
    const handleOnChangeActivityId = (e, data) => {
        setActivityId(data.value)
        const activityInfo = activities.find((act) => act.id === data.value)
        setActivity(activityInfo)
        // set time end if event is planned at exact time
        if (eventType === 'exactTime' && timeStart !== '') {
            setTimeEnd(moment(timeStart, 'hh:mm').locale('sk').add(activityInfo.duration, 'minutes').format('LT'))
        }
        // set places options when activity id is changed
        // setSelectOptions(activityInfo.addresses, placesOptions);
        const newOptions = []
        for (let i = 0; i < activityInfo.addresses.length; i++) {
            let option = {
                key: activityInfo.addresses[i].id,
                text: activityInfo.addresses[i].name,
                value: activityInfo.addresses[i].id,
            }
            newOptions.push(option)
        }
        setPlacesOptions(newOptions)
    }

    const handleOnChangeDate = (e, data) => {
        setDate(data.value)
    }

    const handleOnChangeTimeStart = (e, data) => {
        setTimeStart(data.value)
        // set time end if event is planned at exact time
        if (eventType === 'exactTime' && Object.keys(activity).length !== 0) {
            setTimeEnd(moment(data.value, 'hh:mm').locale('sk').add(activity.duration, 'minutes').format('LT'))
        }
    }

    const handleOnChangeTimeEnd = (e, data) => {
        setTimeEnd(data.value)
    }

    const handleOnChangeRepeat = (e, data) => {
        setRepeat(data.value)
    }

    const handleOnChangeDateRepeat = (e, data) => {
        setDateRepeat(data.value)
    }

    const handleOnChangeValidUntil = (e, data) => {
        setValidUntil(data.value)
    }

    const handleOnChangeNote = (e, data) => {
        setNote(data.value)
    }

    const handleOnChangePlace = (e, data) => {
        setPlace(data.value)
    }

    // submit form
    const handleSubmit = async (event) => {
        event.preventDefault()

        // dataset
        const data = isPermanent ? {
            activity: activityId,
            address: place,
            note: note,
            is_discounted: isDiscounted,
            is_paid: isPaid,
            valid_until: validUntil
        } : {
            activity: activityId,
            date: date,
            time_from: timeStart,
            time_to: eventType === 'exactTime' ? '' : timeEnd,
            address: place,
            note: note,
            repeat:
                repeat === '1'
                    ? false
                    : {
                        days: {
                            monday: document.getElementById('repeatOnMonday').checked,
                            tuesday: document.getElementById('repeatOnTuesday').checked,
                            wednesday: document.getElementById('repeatOnWednesday').checked,
                            thursday: document.getElementById('repeatOnThursday').checked,
                            friday: document.getElementById('repeatOnFriday').checked,
                            saturday: document.getElementById('repeatOnSaturday').checked,
                            sunday: document.getElementById('repeatOnSunday').checked,
                        },
                        repeat_until: dateRepeat,
                    },
            is_discounted: isDiscounted,
            is_paid: isPaid,
        }

        setIsProcessing(true)

        let formError = false

        // validate form fields
        if (
            !isPermanent && (
                isDateError(date) ||
                isTimeError(timeStart) ||
                (repeat === '2' ? isDateError(dateRepeat) : false) ||
                (eventType === 'interval' ? isTimeError(timeEnd) : false))
        ) {
            formError = true
        }

        if (!formError) {
            if (await planningService.createEvent(data, isPermanent)) {
                setIsProcessing(false)
                newMessage('Udalosť bola úspešne vytvorená!')
            } else {
                newMessage('Vytvorenie udalosti prebehlo neúspešne!')
            }
        }
        setIsProcessing(false)
    }

    //template
    return (
        <Fragment>
            <Dimmer active={isLoading} inverted>
                <Loader />
            </Dimmer>

            <Modal.Content>
                <Grid centered>
                    <Grid.Column mobile={16} tablet={10} computer={10}>
                        <Form id="add-event-form" onSubmit={handleSubmit}>
                            <Form.Select
                                width={16}
                                required
                                label="Aktivita"
                                placeholder="Vyber aktivitu"
                                options={activitiesOptions}
                                value={activityId}
                                onChange={handleOnChangeActivityId}
                            />

                            {!isPermanent &&
                                <>
                                    <Form.Group>
                                        <DateInput
                                            width={16}
                                            required
                                            localization="sk"
                                            label="Dátum konania aktivity"
                                            placeholder="YYYY-MM-DD"
                                            dateFormat="YYYY-MM-DD"
                                            value={date}
                                            onChange={handleOnChangeDate}
                                            minDate={moment()}
                                            iconPosition="right"
                                            animation="none"
                                            closable
                                            hideMobileKeyboard
                                            error={!isEmpty(date) ? isDateError(date) : false}
                                        />
                                    </Form.Group>

                                    <Form.Group widths="equal">
                                        <TimeInput
                                            required
                                            label="Čas začiatku"
                                            placeholder="HH:MM"
                                            timeFormat="24"
                                            value={timeStart}
                                            onChange={handleOnChangeTimeStart}
                                            iconPosition="right"
                                            animation="none"
                                            closable
                                            hideMobileKeyboard
                                            error={!isEmpty(timeStart) ? isTimeError(timeStart) : false}
                                        />
                                        <TimeInput
                                            required={eventType === 'interval'}
                                            label="Čas ukončenia"
                                            placeholder="HH:MM"
                                            timeFormat="24"
                                            value={timeEnd}
                                            onChange={handleOnChangeTimeEnd}
                                            iconPosition="right"
                                            animation="none"
                                            disabled={eventType === 'exactTime'}
                                            closable
                                            hideMobileKeyboard
                                            error={!isEmpty(timeEnd) && eventType === 'interval' ? isTimeError(timeEnd) : false}
                                        />
                                    </Form.Group>
                                </>
                            }

                            <Form.Group>
                                <Form.Select
                                    width={16}
                                    label="Miesto udalosti"
                                    placeholder="Miesto udalosti"
                                    required
                                    options={placesOptions}
                                    disabled={activityId > 0 ? false : true}
                                    onChange={handleOnChangePlace}
                                    value={place}
                                />
                            </Form.Group>

                            {isPermanent &&
                                <>
                                    <Divider />
                                    <DateInput
                                        localization="sk"
                                        label="Platné do"
                                        placeholder="YYYY-MM-DD"
                                        dateFormat="YYYY-MM-DD"
                                        value={validUntil}
                                        onChange={handleOnChangeValidUntil}
                                        minDate={moment()}
                                        iconPosition="right"
                                        animation="none"
                                        closable
                                        hideMobileKeyboard
                                        error={!isEmpty(validUntil) ? isDateError(validUntil) : false}
                                    />
                                    <Message info visible>
                                        <Icon name="info circle" /> Bod sa bude zobrazovať na mape do zadaného dátumu (vrátane). Ponechajte prázdne pre <strong>nekonečné zobrazenie</strong>.
                                        <br /> <br />
                                        Bod je možné odobrať z mapy deaktiváciou v zozname pevných bodov.
                                    </Message>
                                </>
                            }

                            <Divider horizontal>Doplňujúce informácie</Divider>

                            {!isPermanent &&
                                <>
                                    <Form.Group>
                                        <Form.Select
                                            width={8}
                                            required
                                            label="Opakovanie udalosti"
                                            options={eventRepeatOptions}
                                            value={repeat}
                                            onChange={handleOnChangeRepeat}
                                        />
                                        <DateInput
                                            width={8}
                                            required={repeat === '2'}
                                            localization="sk"
                                            label="Opakovať do"
                                            placeholder="YYYY-MM-DD"
                                            dateFormat="YYYY-MM-DD"
                                            value={dateRepeat}
                                            onChange={handleOnChangeDateRepeat}
                                            minDate={moment()}
                                            className={repeat === '1' ? 'hide' : ''}
                                            iconPosition="right"
                                            animation="none"
                                            closable
                                            hideMobileKeyboard
                                            error={!isEmpty(dateRepeat) ? isDateError(dateRepeat) : false}
                                        />
                                    </Form.Group>

                                    <Form.Field className={repeat === '1' ? 'hide' : ''}>
                                        <label
                                            style={{
                                                display: 'block',
                                                marginTop: '0.5rem',
                                            }}
                                        >
                                            Dni opakovania
                                        </label>
                                    </Form.Field>
                                    <Form.Group
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '0.3rem',
                                            marginBottom: '0.5rem',
                                        }}
                                    >
                                        <Form.Checkbox
                                            label="PO"
                                            id="repeatOnMonday"
                                            className={repeat === '1' ? 'hide' : ''}
                                        />
                                        <Form.Checkbox
                                            label="UT"
                                            id="repeatOnTuesday"
                                            className={repeat === '1' ? 'hide' : ''}
                                        />
                                        <Form.Checkbox
                                            label="ST"
                                            id="repeatOnWednesday"
                                            className={repeat === '1' ? 'hide' : ''}
                                        />
                                        <Form.Checkbox
                                            label="ŠT"
                                            id="repeatOnThursday"
                                            className={repeat === '1' ? 'hide' : ''}
                                        />
                                        <Form.Checkbox
                                            label="PI"
                                            id="repeatOnFriday"
                                            className={repeat === '1' ? 'hide' : ''}
                                        />
                                        <Form.Checkbox
                                            label="SO"
                                            id="repeatOnSaturday"
                                            className={repeat === '1' ? 'hide' : ''}
                                        />
                                        <Form.Checkbox
                                            label="NE"
                                            id="repeatOnSunday"
                                            className={repeat === '1' ? 'hide' : ''}
                                        />
                                    </Form.Group>
                                </>
                            }

                            <Form.TextArea
                                label="Poznámka"
                                placeholder="Poznámka k aktivite ..."
                                value={note}
                                onChange={handleOnChangeNote}
                            />

                            <Divider />

                            <Form.Checkbox
                                label={user.is_town ? 'Povoliť dobrovoľný príspevok' : 'Nastaviť ako platený termín'}
                                checked={isPaid}
                                onChange={() => setIsPaid(!isPaid)}
                            />

                            {!user.is_town && (
                                <Form.Checkbox
                                    disabled={!isPaid}
                                    label="Aplikovať zľavu na termín"
                                    checked={isDiscounted}
                                    onChange={() => setIsDiscounted(!isDiscounted)}
                                />
                            )}
                        </Form>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={6} computer={6}>
                        <Header as="h4">O AKTIVITE</Header>
                        <Header as="h5">
                            <Icon name="clock" />
                            Dĺžka aktivity
                        </Header>
                        <Header.Subheader>
                            {Object.keys(activity).length === 0 ? '-' : `${activity.duration} minút`}
                        </Header.Subheader>
                        <Header as="h5">{user.is_town ? 'Minimálna výška príspevku' : 'Cena aktivity'}</Header>
                        <Header.Subheader>
                            {Object.keys(activity).length === 0 ? '-' : `${activity.price} EUR`}
                        </Header.Subheader>
                        {!user.is_town && (
                            <>
                                <Header as="h5">Cena po zľave</Header>
                                <Header.Subheader>
                                    {Object.keys(activity).length === 0 ? '-' : `${activity.discountPrice || 0} EUR`}
                                </Header.Subheader>
                            </>
                        )}
                    </Grid.Column>
                </Grid>
            </Modal.Content>

            <Modal.Actions>
                <Button labelPosition="left" icon onClick={backModalStep}>
                    Späť
                    <Icon name="left chevron" />
                </Button>
                <Button
                    color="pink"
                    content="Uložiť"
                    loading={isProcessing}
                    disabled={isPermanent ? (isEmpty(activityId) || isEmpty(place) || isProcessing) : (
                        isProcessing ||
                        isEmpty(activityId) ||
                        isEmpty(date) ||
                        isEmpty(timeStart) ||
                        isEmpty(place) ||
                        (repeat === '2' ? isEmpty(dateRepeat) : false) ||
                        (eventType === 'interval' ? isEmpty(timeEnd) : false))
                    }
                    type="submit"
                    form="add-event-form"
                />
            </Modal.Actions>
        </Fragment>
    )
}

export default PlanningAddEventForm
