// react
import React, { Fragment, useEffect, useState } from 'react'
// store
import { API, PAGINATION_LIMIT } from '../../../../store/config'
// components
import DimmerLoader from '../../../../components/others/DimmerLoader'
import { Table, Pagination, Icon } from 'semantic-ui-react'

// component
function ClientInvoicesTable({ invoices, isLoading }) {
    const [ready, setReady] = useState(false)
    // state
    const [invoicesList, setInvoicesList] = useState(invoices.slice(0, PAGINATION_LIMIT))

    // set data to table
    useEffect(() => {
        setInvoicesList(invoices.slice(0, PAGINATION_LIMIT))
        setReady(true)
    }, [invoices])

    const handleChangePage = (event, data) => {
        setReady(false)
        setInvoicesList(
            invoices.slice(data.activePage * PAGINATION_LIMIT - PAGINATION_LIMIT, data.activePage * PAGINATION_LIMIT)
        )
        setReady(true)
    }

    // template
    return (
        <Fragment>
            <DimmerLoader active={isLoading} />
            <Table striped unstackable style={{ overflow: 'auto' }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Číslo faktúry</Table.HeaderCell>
                        <Table.HeaderCell>Služba</Table.HeaderCell>
                        <Table.HeaderCell>Celková cena</Table.HeaderCell>
                        <Table.HeaderCell>Objednané</Table.HeaderCell>
                        <Table.HeaderCell>Stav</Table.HeaderCell>
                        <Table.HeaderCell>Faktúra</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {invoicesList.length === 0 && (
                        <Table.Row>
                            <Table.Cell colSpan={6} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                Neboli najdené žiadne záznamy
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {invoicesList.map((invoice) => (
                        <Table.Row key={invoice.id}>
                            <Table.Cell>{invoice.invoice_number}</Table.Cell>
                            <Table.Cell>
                                {invoice.service.service_type === 1 ? 'Objednávka Termínu' : 'Objednávka'}
                            </Table.Cell>
                            <Table.Cell>{`${invoice.total_amount} €`}</Table.Cell>
                            <Table.Cell>{invoice.ordered_when}</Table.Cell>
                            <Table.Cell>{invoice.status === 3 ? 'Zaplatená' : 'Nezaplatená'}</Table.Cell>
                            <Table.Cell>
                                {invoice.status === 3 &&
                                    invoice.ordered_items.filter((item) => item.event.event_type === 'solidarity')
                                        .length === 0 &&
                                    ready && (
                                        <a
                                            href={API.INVOICES + invoice.id + '/docs/'}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <Icon name="file pdf" style={{ fontSize: '1.2rem', color: 'red' }} />
                                        </a>
                                    )}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="8">
                            <Pagination
                                defaultActivePage={1}
                                totalPages={Math.ceil(invoices.length / PAGINATION_LIMIT)}
                                onPageChange={handleChangePage}
                                boundaryRange={0}
                                siblingRange={0}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Fragment>
    )
}

export default ClientInvoicesTable
