// react
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Grid /*, Accordion*/, Icon, Popup, Container, Button } from 'semantic-ui-react'
import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    EmailIcon,
    WhatsappIcon,
    TelegramIcon,
    TwitterIcon,
    FacebookIcon,
} from 'react-share'
import moment from 'moment'

import { addToBasket, removeFromBasket, removeProductFromBasketConfiguration } from '../../store/actions'

// component
export default function ActivityInfo(props) {
    const dispatch = useDispatch()
    const basket = useSelector((state) => state.basket)
    const [data] = useState(props.data ? props.data : {})
    const [provider] = useState(props.data ? props.data.activity.provider : {})
    const [copied, setCopied] = useState(false)

    return (
        <>
            <Container
                style={{
                    width: '75%',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    marginTop: '1rem',
                }}
            >
                <div class="grid-container">
                    <div class="column column-1">
                        <div className="box">
                            <div>
                                <h2>Informácie o aktivite</h2>

                                <div
                                    className="customLinks"
                                    dangerouslySetInnerHTML={{ __html: data?.activity?.description || '' }}
                                />
                            </div>

                            <div>
                                <h2>Poskytovateľ</h2>

                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column mobile={4} tablet={4} computer={3}>
                                            <ul>
                                                <li>MENO:</li>
                                                <li>TELEFÓN:</li>
                                                <li>E-MAIL:</li>
                                            </ul>
                                        </Grid.Column>
                                        <Grid.Column mobile={12} tablet={12} computer={12}>
                                            <ul>
                                                <li>
                                                    <b>{provider.name}</b>
                                                </li>
                                                <li>
                                                    <b>
                                                        <a href={`tel:${provider.phone}`}>{provider.phone}</a>
                                                    </b>
                                                </li>
                                                <li>
                                                    <b>
                                                        <a href={`mailto:${provider.email}`}>{provider.email}</a>
                                                    </b>
                                                </li>
                                            </ul>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>

                            <div>
                                {provider.socials ? (
                                    provider.socials.map((social) => (
                                        <Icon
                                            key={social.name}
                                            name={social.icon}
                                            size="big"
                                            onClick={() => window.open(social.link, '_blank')}
                                        />
                                    ))
                                ) : (
                                    <></>
                                )}
                            </div>

                            <h3>Zdieľajte ďalej</h3>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.5rem' }}>
                                <FacebookShareButton style={{ paddingRight: '0.5rem' }} url={window.location.href}>
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <TwitterShareButton style={{ paddingRight: '0.5rem' }} url={window.location.href}>
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>
                                <TelegramShareButton style={{ paddingRight: '0.5rem' }} url={window.location.href}>
                                    <TelegramIcon size={32} round />
                                </TelegramShareButton>
                                <WhatsappShareButton style={{ paddingRight: '0.5rem' }} url={window.location.href}>
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                                <EmailShareButton style={{ paddingRight: '0.5rem' }} url={window.location.href}>
                                    <EmailIcon size={32} round />
                                </EmailShareButton>
                                <Popup
                                    hideOnScroll
                                    size="small"
                                    trigger={
                                        <CopyToClipboard text={window.location.href}>
                                            <div onClick={() => setCopied(true)}>
                                                <Icon circular inverted style={{ fontSize: '1.1rem' }} name="code" />
                                                {copied && <span style={{ marginTop: '1rem' }}>Skopirované!</span>}
                                            </div>
                                        </CopyToClipboard>
                                    }
                                    content="Skopírovať odkaz"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="column column-2">
                        <div>
                            {data.starts && data.ends &&
                                <div>
                                    <Icon name="calendar alternate outline" style={{ marginTop: '1rem' }} />
                                    {moment(data.starts).format('DD.MM.YYYY HH:mm') +
                                        ' - ' +
                                        moment(data.ends).format('HH:mm')}
                                </div>
                            }
                            <div>
                                <Icon name="map marker alternate" style={{ marginTop: '1rem' }} />
                                {data.address.name}
                            </div>
                            <div>
                                <Icon name="clock outline" style={{ marginTop: '1rem', color: 'black' }} />
                                {data.activity.durations}{' '}
                                {data.activity.durationType === 5
                                    ? 'min.'
                                    : data.activity.durationType === 1
                                        ? 'dňová'
                                        : 'hod.'}{' '}
                                aktivita
                            </div>

                            {data.event_type === 'solidarity' && data.is_paid && (
                                <div>
                                    <Icon
                                        name="circle info"
                                        color="pink"
                                        style={{
                                            fontWeight: 'bold',
                                            marginTop: '1rem',
                                            color: 'black',
                                        }}
                                    />
                                    Dobrovoľný príspevok
                                </div>
                            )}
                        </div>

                        {data.is_paid && (
                            <div style={{ marginTop: '2rem' }}>
                                {basket.future_events.indexOf(data.id) === -1 &&
                                    basket.events.indexOf(data.id) === -1 ? (
                                    <Button onClick={(e) => dispatch(addToBasket(data))} className="add">
                                        {data.event_type === 'solidarity' ? 'PRISPIEŤ' : 'PRIDAŤ DO KOŠÍKA'}
                                    </Button>
                                ) : (
                                    <Button
                                        style={{
                                            background: 'red !important',
                                        }}
                                        className="remove"
                                        onClick={(e) => {
                                            dispatch(removeFromBasket(data))
                                            dispatch(removeProductFromBasketConfiguration(data.id))
                                        }}
                                    >
                                        ODOBRAŤ Z KOŠÍKA
                                    </Button>
                                )}
                            </div>
                        )}

                        {props?.position && (
                            <a
                                class="navigate"
                                href={props?.position ? props?.getQueryParamsToNavigation() : '#'}
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{ color: 'black' }}
                            >
                                <span>NAVIGOVAŤ</span>
                            </a>
                        )}
                    </div>
                </div>
            </Container>
        </>
    )
}
