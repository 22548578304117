// react
import React from "react";
// store
import { routes } from "../../store/routes";
// components
import { Header, Button } from "semantic-ui-react";

// component
const EmptyBasket = () => {
    // template
    return (
        <Header as="h3" textAlign="center" style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            Košík neobsahuje žiadne položky. <br />
            <Button
                style={{ marginTop: "2rem" }}
                type="button"
                className="button-pay-fluid"
                as="a"
                href={routes.SEARCH}
                size="large"
                color="green"
            >
                Pokračovať vo vyhľadávaní
            </Button>
        </Header>
    );
};

export default EmptyBasket;
