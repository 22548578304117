// react
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Route, Switch } from 'react-router-dom'
// store
import { routes } from '../../store/routes'
import { Role } from '../../store/services/helpers/roles'
import { authService } from '../../store/services/ServiceAuth'
// components
import Welcome from './Welcome'
import Profile from './profile/Profile'
import ClientInvoices from './client/my-orders/ClientInvoices'
import ClientEvents from './client/my-events/ClientEvents'
import UsersListing from './admin/users-management/UsersListing'
import Bundles from './admin/bundles/Bundles'
import Profits from './admin/profits/Profits'
import Rewards from './admin/rewards/Rewards'
import Requests from './admin/requests/Requests'
import Payments from './provider/payments/Payments'
import Planning from './provider/planning/Planning'
import Categories from './admin/categories/Categories'
import Activities from './admin/activities/Activities'
import Addresses from './provider/addresses/Addresses'
import MyActivities from './provider/activities/MyActivities'
import DashboardLink from '../../components/others/DashboardLink'
import BillingInformation from './provider/billing/BillingInformation'
import CoachNetworkBase from './provider/coach_network/CoachNetworkBase'
import LinkLogo from '../../components/others/LinkLogo'
import { Button, Divider } from 'semantic-ui-react'
import VerificationForm from './provider/activities/VerificationForm'
// others
import { GAPageView, initGA } from '../../index'
import UsersActivity from './admin/users-activity/UsersActivity'
import FutureEvents from './provider/future_events/FutureEvents'
import Invoices from './provider/invoices/Invoices'
import OrderedEvents from './provider/orders/OrderedEvents'
import SuperModal from '../../components/modals/SuperModal'
// stats views
import ProvidersListView from './admin/stats/views/ProvidersListView'
import ActivitiesListView from './admin/stats/views/ActivitiesListView'
import TownsListView from './admin/stats/views/TownsListView'
import CoachesListView from './admin/stats/views/CoachesListView'
import PaymentsListView from './admin/stats/views/PaymentsListView'

const setNavBar = () => {
    let element = document.querySelector('.dashboardMenu')

    if (element.classList.contains('hide-dashboard-menu')) {
        element.classList.remove('hide-dashboard-menu')
    } else {
        element.classList.add('hide-dashboard-menu')
    }
}

// component
function Dashboard() {
    const history = useHistory()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)

    // Google analytics
    useEffect(() => {
        initGA()
        GAPageView()
    }, [])

    useEffect(() => {
        async function checkUser() {
            if (user?.id && user?.tutorial === false) {
                history.push(routes.TUTORIAL)
                return
            }
        }

        checkUser()
    }, [user, history])

    // useEffect(() => {
    //     if( user?.role === "User" ){
    //         history.push(routes.PROFILE)
    //     }

    // }, [user])

    const logoutAction = () => {
        authService.logout(dispatch)
        history.push(routes.LOGIN)
    }

    const page = (event, url) => {
        history.push(routes.DASHBOARD + url)
        setNavBar()
    }

    // template
    return (
        <div className="cms">
            <nav className="left-menu">
                <LinkLogo />

                <span className="dashboard-icon" onClick={setNavBar}></span>
                <br />
                <div className="dashboardMenu hide-dashboard-menu">
                    {user.role === 'Provider' && (
                        <SuperModal
                            trigger={
                                <Button fluid className="verify-button">
                                    {' '}
                                    Overiť aktivitu{' '}
                                </Button>
                            }
                            content={<VerificationForm />}
                        />
                    )}
                    <Divider />

                    {/* TODO: Rework this part to use links without "task" function */}
                    {user.role === 'Admin' && (
                        <DashboardLink
                            title={'Domov'}
                            task={(e) => page(e, '')}
                            role={Role.All}
                            iconName="home"
                            address=""
                        />
                    )}
                    <DashboardLink
                        title={'Upraviť Profil'}
                        task={(e) => page(e, 'profile')}
                        role={Role.All}
                        iconName="user circle"
                        address="profile"
                    />
                    <DashboardLink
                        title={'Moje Objednávky'}
                        task={(e) => page(e, 'my-orders')}
                        role={Role.User}
                        iconName="list"
                        address="my-orders"
                    />
                    <DashboardLink
                        title={'Moje Termíny'}
                        task={(e) => page(e, 'my-events')}
                        role={Role.User}
                        iconName="list"
                        address="my-events"
                    />
                    <DashboardLink
                        title={'Správa používateľov'}
                        task={(e) => page(e, 'users')}
                        role={Role.Admin}
                        iconName="users"
                        address="users"
                    />
                    <DashboardLink
                        title={'Aktivita používateľov'}
                        task={(e) => page(e, 'users_activity')}
                        role={Role.Admin}
                        iconName="users"
                        address="users_activity"
                    />
                    {/* <DashboardLink
                        title={"Balíčky"}
                        task={(e) => page(e, "bundles")}
                        role={Role.Admin}
                        iconName="setting"
                        address="bundles"
                    /> */}
                    {/* <DashboardLink
                        title={"Prehľad kategórií"}
                        task={(e) => page(e, "categories")}
                        role={Role.Admin}
                        iconName="setting"
                        address="categories"
                    /> */}
                    <DashboardLink
                        title={'Prehľad typov aktivít'}
                        task={(e) => page(e, 'activities')}
                        role={Role.Admin}
                        iconName="setting"
                        address="activities"
                    />
                    <DashboardLink
                        title={'Prehľad ziskovosti'}
                        task={(e) => page(e, 'profits')}
                        role={Role.Admin}
                        iconName="money"
                        address="profits"
                    />
                    <DashboardLink
                        title={'Prehľad odmien'}
                        task={(e) => page(e, 'rewards')}
                        role={Role.Admin}
                        iconName="money bill alternate outline"
                        address="rewards"
                    />
                    <DashboardLink
                        title={'Žiadosti'}
                        task={(e) => page(e, 'requests')}
                        role={Role.Admin}
                        iconName="setting"
                        address="requests"
                    />

                    {/* Provider Links */}
                    <DashboardLink
                        title={'Miesto konania aktivity'}
                        task={(e) => page(e, 'addresses')}
                        role={Role.Provider}
                        iconName="map marker alternate"
                        address="addresses"
                    />
                    <DashboardLink
                        title={'Fakturačné údaje'}
                        task={(e) => page(e, 'billing')}
                        role={Role.Provider}
                        iconName="money bill alternate"
                        address="billing"
                    />
                    <DashboardLink
                        title={'Moje aktivity'}
                        task={(e) => page(e, 'myactivities')}
                        role={Role.Provider}
                        iconName="stopwatch"
                        address="myactivities"
                    />
                    <DashboardLink
                        title={'Plánovanie aktivít'}
                        task={(e) => page(e, 'planning')}
                        role={Role.Provider}
                        iconName="calendar"
                        address="planning"
                    />
                    {/* { !user.is_town && (
                        <DashboardLink
                            title={"Budúce termíny"}
                            task={(e) => page(e, "future_events")}
                            role={Role.Provider}
                            iconName="stopwatch"
                            address="future_events"
                        />
                    )} */}
                    {/* <DashboardLink
                        title={"Prehľad faktúr"}
                        task={(e) => page(e, "invoices")}
                        role={Role.Provider}
                        iconName="list"
                        address="invoices"
                    />

                    <DashboardLink
                        title={"Prehľad objednávok"}
                        task={(e) => page(e, "orders")}
                        role={Role.Provider}
                        iconName="list"
                        address="orders"
                    /> */}

                    {user.is_coach && (
                        <DashboardLink
                            title={'Sieť poskytovateľov'}
                            task={(e) => page(e, 'coach_network')}
                            role={Role.Provider}
                            iconName="sitemap"
                            address="coach_network"
                        />
                    )}

                    {/* Links for ALL */}
                    <DashboardLink
                        title={'Odhlásiť sa'}
                        task={logoutAction}
                        role={Role.All}
                        iconName="power off"
                        address="none"
                    />
                </div>
            </nav>

            <div className="content-container">
                <Switch>
                    <Route exact path={routes.DASHBOARD} component={Welcome} />
                    <Route exact path={routes.STATS_PAYMENTS} component={PaymentsListView} />
                    <Route exact path={routes.STATS_COACHES} component={CoachesListView} />
                    <Route exact path={routes.STATS_TOWNS} component={TownsListView} />
                    <Route exact path={routes.STATS_PROVIDERS} component={ProvidersListView} />
                    <Route exact path={routes.STATS_ACTIVITIES} component={ActivitiesListView} />
                    <Route exact path={routes.PROFILE} component={Profile} />
                    <Route exact path={routes.USER_MANAGEMENT} component={UsersListing} />
                    <Route exact path={routes.USER_ACTIVITY} component={UsersActivity} />
                    <Route path={routes.BUNDLES} component={Bundles} />
                    <Route path={routes.CATEGORIES} component={Categories} />
                    <Route path={routes.ACTIVITIES} component={Activities} />
                    <Route path={routes.PROFITS} component={Profits} />
                    <Route path={routes.REWARDS} component={Rewards} />
                    <Route path={routes.REQUESTS} component={Requests} />
                    <Route path={routes.ADDRESSES} component={Addresses} />
                    <Route path={routes.BILLING} component={BillingInformation} />
                    <Route path={routes.COACH_NETWORK} component={CoachNetworkBase} />
                    <Route path={routes.FUTURE_EVENTS} component={FutureEvents} />
                    <Route path={routes.PLANNING} component={Planning} />
                    <Route path={routes.MY_ACTIVITIES} component={MyActivities} />
                    <Route path={routes.PAYMENTS} component={Payments} />
                    <Route path={routes.INVOICES} component={Invoices} />
                    <Route path={routes.MY_ORDERS} component={ClientInvoices} />
                    <Route path={routes.MY_EVENTS} component={ClientEvents} />
                    <Route path={routes.ORDERED_EVENTS} component={OrderedEvents} />
                </Switch>
            </div>
        </div>
    )
}

export default Dashboard
