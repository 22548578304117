// react
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
// components
import { Button, Card, Icon, Label, Input, Table, Popup, Grid } from "semantic-ui-react";
// others
import moment from "moment";

// component
const BasketItem = ({
    as,
    item,
    index,
    items,
    setItems,
    removeProductFromBasket,
    removeItemFromView,
}) => {
    const [amount, setAmount] = useState(items.find(basketItem => basketItem.id === item.id)?.amount || 1)

    const removeItem = (item) => {
        removeProductFromBasket(item);

        if (removeItemFromView) {
            removeItemFromView(item);
        }
    };

    const handleAction = (action, custom) => {
        custom = custom || 1

        if (action === "custom") {
            if (custom > 0) {
                setItems(prev => prev.map(basketItem => {
                    if (basketItem.id === item.id) {
                        basketItem.amount = custom
                    }
                    return basketItem
                }))
                setAmount(custom)
            } else {
                removeItem(item)
            }
        }
        if (action === "increment") {
            setItems(prev => prev.map(basketItem => {
                if (basketItem.id === item.id) {
                    basketItem.amount = basketItem.amount + 1
                }

                return basketItem
            }))

            setAmount(prev => prev + 1)
        }
        if (action === "decrement") {
            if ((amount - 1) > 0) {
                setItems(prev => prev.map(basketItem => {
                    if (basketItem.id === item.id) {
                        basketItem.amount = basketItem.amount - 1
                    }
                    return basketItem
                }))
                setAmount(prev => prev - 1)
            } else {
                removeItem(item)
            }
        }
    }

    useEffect(() => {
        setItems(prevState => prevState.filter(basketItem => {
            if (basketItem.id === item.id) {
                basketItem.amount = amount
            }

            return basketItem
        }))
        // eslint-disable-next-line
    }, [amount])

    // template
    return as === "tableCell" ? (
        <Table.Row verticalAlign="middle" style={{ borderBottom: "1px solid lightgrey" }} key={item.id}>
            <Table.Cell>
                {index + 1}.
            </Table.Cell>
            <Table.Cell>
                <h4>
                    <Popup
                        trigger={
                            <Link style={{ color: "#d60d8c" }} to={`/activity/${item.id}/detail`} target="_blank">{item.name}</Link>
                        }
                        content={
                            <div>
                                <Label style={{ marginBottom: "0.5rem" }} color={
                                    item.event_type === "future"
                                        ? "green"
                                        : item.event_type === "solidarity"
                                            ? "blue"
                                            : "pink"
                                }>
                                    {
                                        item.event_type === "future"
                                            ? "#APfuture"
                                            : item.event_type === "solidarity"
                                                ? item.is_highlighted ? "#APsolidarity - event" : "#APsolidarity"
                                                : "#APbusiness"
                                    }
                                </Label>
                                <div>

                                    <p>
                                        <Icon name="map marker alternate" size="small" />{" "}
                                        <span>
                                            {item.address}
                                        </span>
                                    </p>
                                    <p>
                                        {item.starts &&
                                            <>
                                                <Icon name="clock outline" />{" "}
                                                <span>
                                                    {moment(item.starts).utc().format("DD.MM.YYYY HH:mm")}
                                                </span>
                                            </>
                                        }
                                        {(item.event_type === "solidarity" && item.is_paid === true) &&
                                            <>
                                                <br />
                                                <Icon
                                                    name="circle info"
                                                    color="blue"
                                                    style={{
                                                        fontWeight: 'bold',
                                                        color: 'black',
                                                    }}
                                                />
                                                Dobrovoľný príspevok
                                            </>
                                        }
                                    </p>


                                </div>
                            </div>
                        }
                    />
                    {item.is_discounted && (
                        <Label
                            style={{ marginLeft: "2rem" }}
                            color="green"
                            size="tiny"
                            tag
                        >
                            Zľavnený
                        </Label>
                    )}
                </h4>
            </Table.Cell>
            <Table.Cell>
                <Button disabled={item.event_type !== "solidarity"} size="tiny" primary content={" - "} style={{ fontWeight: "bold", background: "transparent", color: "var(--dark)", fontSize: "1.3rem" }} onClick={() => handleAction("decrement")} />
                <Input disabled={item.event_type !== "solidarity"} size="mini" style={{ width: "50px", textAlign: "center", paddingRight: "0.2rem" }} value={amount} onChange={(e, { value }) => handleAction("custom", value)} />
                <Button disabled={item.event_type !== "solidarity"} size="tiny" primary content={" + "} style={{ fontWeight: "bold", background: "transparent", color: "var(--dark)", fontSize: "1.3rem" }} onClick={() => handleAction("increment")} />
            </Table.Cell>
            <Table.Cell>
                <span style={{ fontSize: "1.1rem", fontWeight: "bold", marginRight: "2.5rem" }}>
                    {item.is_discounted && (
                        <>
                            <strike>{parseFloat(item.price * amount).toFixed(2)}</strike>{" "}
                            <span
                                style={{
                                    fontSize: "1rem",
                                    paddingLeft: "1rem",
                                }}
                            >
                                {parseFloat(item.discountPrice * amount).toFixed(2)}{" "}
                                {item.price_type}
                            </span>
                        </>
                    )}
                    {!item.is_discounted && (
                        <>
                            {parseFloat(item.price * amount).toFixed(2)} {item.price_type}
                        </>
                    )}
                </span>
            </Table.Cell>
            <Table.Cell>
                <Button
                    onClick={(event) => removeItem(item)}
                    icon="close"
                    style={{
                        marginLeft: "2rem",
                        padding: "0.5rem 0.8rem",
                        background: "transparent",
                        color: "red",
                        fontWeight: "bold",
                    }}
                />
            </Table.Cell>
        </Table.Row>
    ) : as === "card" ? (
        <Card fluid>
            <Card.Header style={{ padding: "1rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "20px" }}>{index + 1}. </div>
                        <h4 style={{ marginTop: 0 }}>
                            <Popup
                                trigger={
                                    <Link style={{ color: "#d60d8c" }} to={`/activity/${item.id}/detail`} target="_blank">{item.name}</Link>
                                }
                                content={
                                    <div>
                                        <Label style={{ marginBottom: "0.5rem" }} color={
                                            item.event_type === "future"
                                                ? "green"
                                                : item.event_type === "solidarity"
                                                    ? "blue"
                                                    : "pink"
                                        }>
                                            {
                                                item.event_type === "future"
                                                    ? "#APfuture"
                                                    : item.event_type === "solidarity"
                                                        ? item.is_highlighted ? "#APsolidarity - event" : "#APsolidarity"
                                                        : "#APbusiness"
                                            }
                                        </Label>
                                        <div>

                                            <p>
                                                <Icon name="map marker alternate" size="small" />{" "}
                                                <span>
                                                    {item.address}
                                                </span>
                                            </p>
                                            <p>
                                                <Icon name="clock outline" />{" "}
                                                <span>
                                                    {moment(item.starts).utc().format("DD.MM.YYYY HH:mm")}
                                                </span>
                                                {(item.event_type === "solidarity" && item.is_paid === true) &&
                                                    <>
                                                        <br />
                                                        <Icon
                                                            name="circle info"
                                                            color="blue"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                color: 'black',
                                                            }}
                                                        />
                                                        Dobrovoľný príspevok
                                                    </>
                                                }
                                            </p>


                                        </div>
                                    </div>
                                }
                            />
                            {item.is_discounted && (
                                <Label
                                    style={{ marginLeft: "2rem" }}
                                    color="green"
                                    size="tiny"
                                    tag
                                >
                                    Zľavnený
                                </Label>
                            )}
                        </h4>
                    </div>
                    <Button
                        onClick={(event) => removeItem(item)}
                        icon="close"
                        style={{
                            marginLeft: "2rem",
                            padding: "0.5rem 0.8rem",
                            background: "transparent",
                            color: "red",
                            fontWeight: "bold",
                        }}
                    />
                </div>
            </Card.Header>
            <Card.Content>
                <Grid verticalAlign="middle">
                    <Grid.Row columns={2} style={{ padding: 0 }}>
                        <Grid.Column style={{ padding: 0 }}>
                            <Button disabled={item.event_type !== "solidarity"} size="tiny" primary content={" - "} style={{ fontWeight: "bold", background: "transparent", color: "var(--dark)", fontSize: "1.3rem" }} onClick={() => handleAction("decrement")} />
                            <Input disabled={item.event_type !== "solidarity"} size="mini" style={{ width: "50px", textAlign: "center", paddingRight: "0.2rem" }} value={amount} onChange={(e, { value }) => handleAction("custom", value)} />
                            <Button disabled={item.event_type !== "solidarity"} size="tiny" primary content={" + "} style={{ fontWeight: "bold", background: "transparent", color: "var(--dark)", fontSize: "1.3rem" }} onClick={() => handleAction("increment")} />

                        </Grid.Column>
                        <Grid.Column style={{ padding: 0, textAlign: "right" }}>
                            <span style={{ fontSize: "1.1rem", fontWeight: "bold", marginRight: "2.5rem" }}>
                                {item.is_discounted && (
                                    <>
                                        <strike>{parseFloat(item.price * amount).toFixed(2)}</strike>{" "}
                                        <span
                                            style={{
                                                fontSize: "1rem",
                                                paddingLeft: "1rem",
                                            }}
                                        >
                                            {parseFloat(item.discountPrice * amount).toFixed(2)}{" "}
                                            {item.price_type}
                                        </span>
                                    </>
                                )}
                                {!item.is_discounted && (
                                    <>
                                        {parseFloat(item.price * amount).toFixed(2)} {item.price_type}
                                    </>
                                )}
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
        </Card>
    ) : <></>;
};

export default BasketItem;
